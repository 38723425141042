import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout";
import SEO from '../components/SEO'
// markup
const NotFoundPage = () => {
  return (
    <Layout bg={"gray"} header="light">
        <SEO
            title={"Not Found" }
            description={"404 Not Found"}
        />
        <section className="w-full px-4 md:px-6 py-12 max-w-7xl mx-auto">
            <h1 className="md:text-4xl font-c mb-3  font-display leading-10 text-3xl">Not Found</h1>
            <p className="text-gray-700 leading-6">The page you are looking for does not exist. Please try again by visiting the home page or contact us to help you find what you are looking for.</p>
            {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        </section>
      
   

    </Layout>
  )
}

export default NotFoundPage
